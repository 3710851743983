import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

import './about-us.scss';
import './about-us-media.scss';

import undrawAgreement from '../../images/undraw_agreement.png'

import LeaveRequestModal from "../LeaveRequestModal";

const Partnership = ({ setLeaveRequestType, leaveRequestType }) => {

    const [ leaveRequestModalVisibleAboutUs, setLeaveRequestModalVisibleAboutUs ] = useState(false);

    const { t, i18n } = useTranslation();

    const openModal = () => {
        setLeaveRequestType('partnership');
        setLeaveRequestModalVisibleAboutUs(true);
    }

    return(
        <Element name="partnership">
            {leaveRequestModalVisibleAboutUs && <LeaveRequestModal leaveRequestType={leaveRequestType} setLeaveRequestModalVisibleAboutUs={setLeaveRequestModalVisibleAboutUs}/>}
        <div className="container">
            <div className="partnership">
                <div className="partnership__info">
                    <h1 className="partnership__info-title">{t('partnership.title')}</h1>
                    <h2 className="partnership__info-subtitle">{t('partnership.subtitle')}</h2>
                    <p className="partnership__info-description">{t('partnership.description')}</p>
                    <h2 className="partnership__info-subtitle">{t('partnership.listTitle')}</h2>
                    <p className="partnership__info-description">
                      {t('partnership.listText1')}<br/>
                      {t('partnership.listText2')}<br/>
                      {t('partnership.listText3')}<br/>
                      {t('partnership.listText4')}<br/>
                      {t('partnership.listText5')}
                    </p>
                    <button onClick={openModal} className="buttonTransporent">{t('aboutUsGetConsultation')}</button>
                </div>
                <div className="partnership__cards">
                  <img src={undrawAgreement}/>
                </div>
            </div>
        </div>
        </Element>
    )
}

export default Partnership;