import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import dayjs from 'dayjs';
import { render } from 'storyblok-rich-text-react-renderer';

import './vacancies.scss';
import './vacancies-media.scss';

import VacationModal from "../VacationModal";
import LeaveRequestModal from "../LeaveRequestModal";

import markSvg from '../../images/icons/label.svg';
import write from '../../images/icons/write.svg';


const Vacancies = ({
  setVacationModalType,
  setLeaveRequestType,
  leaveRequestType,
  data,
  countries,
  activeCountry,
  changeCountry,
}) => {
    const { t } = useTranslation();
    const [ vacModal, setVacModal ] = useState(false);
    const [ leaveRequestModalVisibleVacs, setLeaveRequestModalVisileaveVacs ] = useState(false);
    const [ vacModalData, setVacModalData ] = useState({});

    if(vacModal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }

    const closeModal = () => {
        setVacModal(false);
    }

    const changeModalDate = (id) => {
        if (data?.stories[id]) {
            setVacationModalType('vacancies');
            setVacModalData(data.stories[id]);
            setVacModal(true);
        }
    }

    const openRequestModal = () => {
        setLeaveRequestType('vacancies');
        setLeaveRequestModalVisileaveVacs(true);
    }


    return (
        <Element name="vacancies">  
             <div className="vacancies">
        <div className="container">
                <div className="vacancies__title">{t('vacanciesTitle')}</div>
                <div className="vacancies__fillters-wrapper">
                    <div className="vacancies__fillters">
                        {countries.map((it, id) => {
                            return (
                                <button
                                  key={id}
                                  onClick={() => changeCountry(it.id)}
                                  className={it.id === activeCountry ? 'vacancies__filters-btn--active' : 'vacancies__filters-btn'}
                                >
                                  {it.name}
                                </button>
                            )
                        })}
                    </div>
                </div>
                <div className="vacancies__items">
                    {data?.stories.map((it, i) => {
                        return (
                            <>
                                <div className="vacancies__item" key={i}>
                                    <img className="vacancies__item--img" src={it.content.image} alt="img " onClick={() => {changeModalDate(i)}}/>
                                    <div className="vacancies__item-info">
                                        <div onClick={() => {changeModalDate(i)}}>
                                            <h2 className="vacancies__item-info--title">{it.content.title}</h2>
                                            <div className="vacancies__item-info--dop">
                                                <h2 className="vacancies__item-info--site"><img src={markSvg} alt="mark" />{it.content.salary}</h2>
                                            </div>
                                            <p className="vacancies__item-info--desc">
                                              {render(it.content.short_description)}
                                            </p>
                                        </div>
                                        
                                        <div className="vacancies__item-info--top">
                                            <h2 className="vacancies__item-info--date">{dayjs(it.published_at).format('YYYY-MM-DD')}</h2>
                                            <h3 className="vacancies__item-info--btn" onClick={openRequestModal}><img src={write} alt="ico"/>{t('respond')}</h3>
                                        </div>
                                    </div>
                                </div> 
                            </>
                            
                        )
                    })}
                </div>
                {/*vacFilter.length >= 8 ? <button onClick={hendleSeeAll} className="buttonTransporent">{vacsVisible ? t('hide') : t('showMore')}</button> : ''*/}
            </div>
        </div>
        {leaveRequestModalVisibleVacs && (
          <LeaveRequestModal
            leaveRequestType={leaveRequestType}
            setLeaveRequestModalVisileaveVacs={setLeaveRequestModalVisileaveVacs}
          />
        )}
        {vacModal && (
          <VacationModal
            VacData={vacModalData}
            closeModalVacs={closeModal}
          />
        )}
        </Element>
    )
} 

export default Vacancies;