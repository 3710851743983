import React from "react";
import { useTranslation } from "react-i18next";
import { render } from 'storyblok-rich-text-react-renderer';


import './vacationModal.scss';
import './vacationModal-media.scss';

import closeModal from '../../images/icons/close-modal.svg';

const VacationModal = ({
    closeModalVacs,
    vacationModalClose,
    VacData,
    vacationModalType,
    setLeaveRequestModalVisible,
  }) => {

    const modalClose = () => {
      closeModalVacs();
    }

 const clickBtn = () => {
    modalClose();
    //setLeaveRequestModalVisible(true);
 }
    
 const { t } = useTranslation();

    return (
        <div className="vacationModal-wrapper" onClick={e => (e.currentTarget === e.target) && modalClose()}>
            <div className="vacationModal">
                <img className="modal-close" alt="modal close" src={closeModal} onClick={modalClose} />
                {/*<img className="vacationModal-left" src={VacData?.content?.image} alt="vacation img"/>*/}
                <div className="vacationModal__info">
                    <h1>{VacData?.content?.title}</h1>
                    <div className="info">
                        <h2 className="site">{VacData?.content?.location}</h2>
                        <h2 className="salary">
                          <span>{VacData?.content?.salary}</span>
                        </h2>
                    </div>
                    <div className="description">
                      {render(VacData?.content?.description)}
                    </div>
                    <button onClick={clickBtn} className="buttonBlue">{t('leaveRequest')}</button>
                </div>
            </div>
        </div>
    )
}

export default VacationModal;