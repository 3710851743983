import React, { useEffect, useState } from 'react';

import AboutUs from './components/AboutUs';
import Header from './components/Header';
import Vacancies from './components/Vacancies';
import Tutorial from './components/Tutorial';
import Footer from './components/Footer';
import Partnership from './components/Partnership';
import { getStoryblokApi } from "@storyblok/react/rsc";
import { useTranslation } from "react-i18next";

import './global-style.css';
import './stylesheet.css'


function App() {

  const [vacationModalType, setVacationModalType] = useState('')
  const [leaveRequestType, setLeaveRequestType] = useState('')
  const [data, setData] = useState(null)
  const [hotList, setHotList] = useState(null)
  const [loading, setLoading] = useState(false)
  const { i18n, t } = useTranslation();
  const [activeCountry, setActiveCoutry] = useState('all');

  const COUNTRIES = [
    {name: t('all'), id: 'all'},
    {name: t('pl'), id: 'poland'},
    {name: t('nl'), id: 'netherlands'},
    {name: t('de'), id: 'germany'},
    {name: t('en'), id: 'england'},
    {name: t('au'), id: 'australia'},
  ];

  const getData = async (lang, country) => {
    setLoading(true);
    try {
      const storyblokApi = getStoryblokApi();
      const starts_with = activeCountry === 'all' ? {} : { starts_with: country }; 
      const { data } = await storyblokApi.get(`cdn/stories`, {
        language: lang || 'uk',
        fallback_lang: 'default',
        ...starts_with,
      });
      setData(data);
    } catch (err) {
      console.log(err, ' => getData err');
    } finally {
      setLoading(false);
    }
  };

  const getHotVacancies = async (lang) => {
    try {
      const storyblokApi = getStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/stories`, {
        language: lang || 'uk',
        fallback_lang: 'default',
        with_tag: 'hot',
      });
      setHotList(data);
    } catch (err) {
      console.log(err, ' => getData err');
    }
  }

  useEffect(() => {
    getData(i18n.language);
    getHotVacancies(i18n.language);
  }, [i18n.language]);

  const changeCountry = (id) => {
    setActiveCoutry(id);
    getData(i18n.language, id);
  };
  

  const vacancies = [
    {
      title: 'Пакувальник суші',
      site: 'Польща, Познань',
      salary: 7,
      description: 'Пакувальник на фабриці суші м.Познань.\nНескладна робота, без норм і фізичного навантаження!\nПрацевлаштовуємо громадян України, Білорусі, Молдови, Казахстану.\nВ обов\'язки входить пакування продукції, підготовка продуктів до пакування.',
      date: '13.01.24',
      country: 'pl',
      img: '../images/vac-1.png'
    },
  ]

  return (
    <div className="App">
      <Header 
        vacancies={hotList} 
        vacationModalType={vacationModalType} 
        setVacationModalType={setVacationModalType} 
        leaveRequestType={leaveRequestType} 
        setLeaveRequestType={setLeaveRequestType} 
      />
      <AboutUs 
        setLeaveRequestType={setLeaveRequestType}
        leaveRequestType={leaveRequestType}
      />
      <Tutorial />
      {!loading && (
        <Vacancies 
          vacancies={vacancies}
          data={data}
          vacationModalType={vacationModalType} 
          setVacationModalType={setVacationModalType}  
          leaveRequestType={leaveRequestType} 
          setLeaveRequestType={setLeaveRequestType} 
          countries={COUNTRIES}
          activeCountry={activeCountry}
          changeCountry={changeCountry}
        />
      )}
      <Partnership 
        setLeaveRequestType={setLeaveRequestType}
        leaveRequestType={leaveRequestType}
      />
      <Footer />
    </div>
  );
}

export default App;
