import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './18n';
import { storyblokInit, apiPlugin } from "@storyblok/react";

storyblokInit({
  accessToken: "oPLz61fNkfgcTorBG7xdVQtt",
  use: [apiPlugin],
  // bridge: false,
  // apiOptions: {},
  // richText: {},
  // enableFallbackComponent: false,
  // customFallbackComponent: FallbackComponent,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </React.StrictMode>
);

